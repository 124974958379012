import React from "react"

import { LinkButton } from "../../../UI/Common"

import PaddingWrapper from "./PaddingWrapper"

const ButtonContent = ({config, button:{ url, title, target}}) => (
  <PaddingWrapper padding={config?.padding}>
    <LinkButton title={title} link={url} target={target} />
  </PaddingWrapper>
)

export default ButtonContent
